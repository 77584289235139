import { routeToUrl } from "@app-routes";
import { smartAddressLoadOptions } from "@shared/utils/smart-address-load-options";
import { isNil } from "@uxf/core/utils/is-nil";
import { slugify } from "@uxf/core/utils/slugify";
import { Button } from "@uxf/ui/button";
import { Combobox, ComboboxOption } from "@uxf/ui/combobox";
import { useRouter } from "next/router";
import { FormEventHandler, useState } from "react";

export function SmartAddressForm() {
    const { push } = useRouter();

    const [smartAddress, setSmartAddress] = useState<ComboboxOption<number> | null>(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitHandler: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        if (isNil(smartAddress)) {
            return;
        }

        setIsSubmitting(true);

        await push(
            routeToUrl("address-detail-page", {
                smartAddressId: smartAddress.id,
                slug: slugify(smartAddress.label),
            }),
        );

        setIsSubmitting(false);
    };

    return (
        <form onSubmit={submitHandler}>
            <Combobox
                isDisabled={isSubmitting}
                label="Jaká adresa vás zajímá?"
                loadOptions={smartAddressLoadOptions}
                name="smart-address"
                noQueryMessage="Pro zobrazení možností začněte psát."
                notFoundMessage="Tato adresa pravěpodobně neexistuje."
                onChange={setSmartAddress}
                placeholder="Zadejte adresu..."
                value={smartAddress}
            />
            <Button className="mt-4 w-full" disabled={isNil(smartAddress) || isSubmitting} type="submit">
                Zjistěte více
            </Button>
            <input className="sr-only" type="submit" />
        </form>
    );
}
