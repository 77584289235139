import { SmartAddressForm } from "@main-zone/components/smart-address-form/smart-address-form";
import { Helmet } from "@shared/components/helmet/helmet";
import { Layout } from "@shared/components/layout/layout";
import { Icon } from "@uxf/ui/icon";

export function Homepage() {
    return (
        <Layout>
            <Helmet title="" />
            <main className="main container">
                <section className="address__search">
                    <div className="address__search--box">
                        <h1 className="address__search--heading">Vyhledávač adres</h1>
                        <p className="address__search--text">
                            Vyhledávač adres, rozšířený o řadu doplňujících informací o adresním místě. Rychlý,
                            pravidelně aktualizovaný s jednoduchým REST API.
                        </p>
                    </div>
                    <div className="address-form">
                        <SmartAddressForm />
                    </div>
                </section>
                <section className="advantages">
                    <h2 className="advantages--heading">Co je na Smart Address tak skvělé?</h2>
                    <div className="advantages__wrapper">
                        <div className="advantages__block">
                            <Icon className="advantages__block--img" name="slack" size={48} />
                            <p className="advantages__block--text">
                                Garantujeme vám aktuálnost dat, funkční a maximálně responzivní API
                            </p>
                        </div>
                        <div className="advantages__block">
                            <Icon className="advantages__block--img" name="check-circle" size={48} />
                            <p className="advantages__block--text">
                                K vyhledání adresy vám stačí jeden endpoint. K získání základních informací a
                                rozšířených informací o adrese další dva
                            </p>
                        </div>
                        <div className="advantages__block">
                            <Icon className="advantages__block--img" name="bell" size={48} />
                            <p className="advantages__block--text">
                                Změny v primárních zdrojích dat pravidelně kontrolujeme a Smart Address pravidelně
                                aktualizujeme
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}
